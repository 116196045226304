<template>
    <div class="hsctemplate">
        <section class="body1200" style="margin-top: 90px;">
            <div style="font-size: 36px;font-weight: bold;text-align: center"> 在这里，你可以将想象付诸实践，全心奔赴梦想</div>
            <div class="htable" style="margin-top: 70px;justify-content: space-between;" >
                <div class="hcell" style="text-align: center;width: 260px;">
                    <div style="color: rgba(32,32,32,0.9);font-size: 24px;font-weight: bold;">以创造者为本</div>
                    <div style="margin-top: 30px;"><img :src="brain"></div>
                    <div style="margin-top: 30px;font-size: 16px;color: rgba(32,32,32,0.7);text-align: left;line-height: 28px;">掌握光伏核心技术，在富有挑战性的环境当中，实现自身梦想和价值。</div>
                </div>
                <div class="hcell" style="text-align: center;width: 260px;">
                    <div style="color: rgba(32,32,32,0.9);font-size: 24px;font-weight: bold;">铸平台共创价值</div>
                    <div style="margin-top: 30px;"><img :src="parachute"></div>
                    <div style="margin-top: 30px;font-size: 16px;color: rgba(32,32,32,0.7);text-align: left;line-height: 28px;">辉腾事业平台已成型，在辉腾追求合作共进，合作共赢，在这里，你的梦想辉腾辅助你快速实现。</div>
                </div>
                <div class="hcell" style="text-align: center;width: 260px;">
                    <div style="color: rgba(32,32,32,0.9);font-size: 24px;font-weight: bold;">公开、公平、公正</div>
                    <div style="margin-top: 30px;" ><img :src="balancetwo"></div>
                    <div style="margin-top: 30px;font-size: 16px;color: rgba(32,32,32,0.7);text-align: left;line-height: 28px;">不谈工龄、不论年龄，所有同事均同等相待，过程看素养、结果论能力，因人定岗，公平竞争与成长。</div>
                </div>
                <div class="hcell" style="text-align: center;width: 260px;">
                    <div style="color: rgba(32,32,32,0.9);font-size: 24px;font-weight: bold;">与行业大牛同行</div>
                    <div style="margin-top: 30px;"><img :src="enquire"></div>
                    <div style="margin-top: 30px;font-size: 16px;color: rgba(32,32,32,0.7);text-align: left;line-height: 28px;">辉腾各团队主要人员均在光伏产业摸爬滚打十多年，他们是行业精英，与精英同行，你就是明天的精英。</div>
                </div>
            </div>
        </section>
        <section style="margin-top: 90px;">
            <div class="rcln2" style="text-align: center;">
                <div style="height:130px;"></div>
                <div style="font-size: 36px;line-height: 36px;color:rgba(255,255,255,0.9);font-weight: bold;">在这里，我们以创造者为本</div>
                <div style="font-size: 24px;line-height: 43px;color:rgba(255,255,255,0.8);margin-top: 69px;">
                    铸平台共创价值<br>
                    辉腾以“铸平台共创价值，用科技改变生活“为使命，公司与人员互为主体、<br>
                    资源共通、价值共创、收益共享的开放合作型团体。人才是最宝贵的资源，是企业在竞争中能否赢得主动的决定性因素之一，<br>
                    一直以来辉腾能源都十分注重吸收和培训人才。<br>
                    在这里，你可以感受一群有“真爱”的小伙伴，向着共同目标奋力奔跑的喜悦，<br>
                    在这里，你可以亲手创造产品，享受它成为万众瞩目的喜悦，<br>
                    让我们一起，全力为理想与使命而战！

                </div>
            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div style="text-align: center;font-size: 36px;font-weight: bold;">在这里，发挥才华的方式多种多样</div>
            <div class="htable" style="margin-top: 70px;justify-content: space-between;" >
                <div class="hcell" style="text-align: center;width: 580px;">
                    <img :src="zpbm1">
                </div>
                <div class="hcell" style="text-align: center;width: 580px;">
                    <img :src="zpbm2">
                </div>
            </div>
            <div class="htable" style="margin-top: 40px;justify-content: space-between;" >
                <div class="hcell" style="text-align: center;width: 580px;">
                    <img :src="zpbm3">
                </div>
                <div class="hcell" style="text-align: center;width: 580px;">
                    <img :src="zpbm4">
                </div>
            </div>
        </section>

        <section class="body1200" style="margin-top: 90px;">
            <div style="text-align: center;font-size: 36px;font-weight: bold;">在这里，不仅有高度，还有温度</div>
            <div style="margin-top: 70px;">

                <div class="htable" style="width: 100%;margin-bottom: 40px;" >
                    <div class="hcell" style="width: 500px;">
                        <img :src="zphd1"  style="display: flex">
                    </div>
                    <div  class="hcell" style="width: 700px;background-color:#ededed;display: flex;">
                        <div style="margin-left: 50px;text-align: left;width: 614px;margin-top:100px;">
                            <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                带薪培训学习
                            </div>
                            <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                我们为每一位新员工准备了分阶段培养项目，针对不同职位、不同部门采取定制化培训<br/>
                                内容，通过多种教学方式结合，将工作中会面临的业务场景、产品知识、专业技能等难<br/>
                                题各个攻破。
                            </div>
                        </div>
                    </div>
                </div>

                <div class="htable" style="width: 100%;margin-bottom: 40px;" >
                    <div  class="hcell" style="width: 700px;background-color:#ededed;display: flex;">
                        <div style="margin-left: 50px;text-align: left;width: 614px;margin-top:80px;">
                            <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                日常福利
                            </div>
                            <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                外派培训学习、商（协）会交流学习、亲属关爱、助学奖励、节日礼品、生日活动、团<br/>
                                建活动、五险+商业保险等。
                            </div>
                        </div>
                    </div>
                    <div class="hcell" style="width: 500px;">
                        <img :src="zphd2"  style="display: flex">
                    </div>
                </div>
                <div class="htable" style="width: 100%;margin-bottom: 40px;" >
                    <div class="hcell" style="width: 500px;">
                        <img :src="zphd3"  style="display: flex">
                    </div>
                    <div  class="hcell" style="width: 700px;background-color:#ededed;display: flex;">
                        <div style="margin-left: 50px;text-align: left;width: 614px;margin-top:100px;">
                            <div style="font-size: 24px;line-height: 36px;font-weight: bold">
                                带薪培训学习
                            </div>
                            <div style="font-size: 16px;color: rgba(0,0,0,0.8);margin-top: 32px;line-height: 28px;">
                                我们为每一位新员工准备了分阶段培养项目，针对不同职位、不同部门采取定制化培训<br/>
                                内容，通过多种教学方式结合，将工作中会面临的业务场景、产品知识、专业技能等难<br/>
                                题各个攻破。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>

</template>
<script>

    export default {
        data () {
            return {
                balancetwo: require('../assets/balance-two.png'),
                brain: require('../assets/brain.png'),
                enquire: require('../assets/enquire.png'),
                parachute: require('../assets/parachute.png'),
                zpbm1: require('../assets/zpbm1.png'),
                zpbm2: require('../assets/zpbm2.png'),
                zpbm3: require('../assets/zpbm3.png'),
                zpbm4: require('../assets/zpbm4.png'),
                zphd1: require('../assets/zphd1.png'),
                zphd2: require('../assets/zphd2.png'),
                zphd3: require('../assets/zphd3.png'),

            }
        },
        components: {
        },
        mounted() {

        },
        methods: {

        },
        watch: {

        },
    }
</script>
<style lang="css" scoped>
    .hsctemplate{
        color: #202020;
        text-align: left;
    }
    .rcln2{
        background-image: url('~assets/rclnb2.png');
        height: 650px;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }

    .farm_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
        /*border: 1px solid red;*/
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .hsc_fa1{
        background-image: url('~assets/hsc_jg1.jpg');
        width: 859px;
        height: 357px;
    }
    .hsc_fa2{
        background-image: url('~assets/hsc_jg2.jpg');
        height: 614px;
    }
    .ghblockclick{
        cursor:pointer;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }

    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }



    .hscgl_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .hscgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hscgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }

</style>
